import apiHelper from './index';

async function getRTCServers(spinner = false) {
    const [error, data] = await apiHelper.get('offer/rtc_server_list/', spinner);
    if(error)
        throw('Error occurred while fetching servers')
    
    return data
}

async function sendRTCOffer(payload , spinner = false) {
    return await apiHelper.post(`offer/create_new_offer/`, payload, false)
}

async function getRTCAnswer(connectionID){
    return await apiHelper.get(`offer/update_offer/${connectionID}`, false)
}

async function closeConnection(connectionID, payload){
    return await apiHelper.put(`offer/update_offer/${connectionID}`, payload, false)
}


export default { getRTCServers, sendRTCOffer, getRTCAnswer, closeConnection};