import { Rectangle } from './Shapes/region';
import { Arrow } from './Shapes/arrow';
import { Circle } from './Shapes/circle'
import { Image } from './Shapes/image';
import { Text } from './Shapes/text';
import { Gif } from './Shapes/gif';

export const TYPES = {
  rectangle: 'Rectangle',
  arrow: 'Arrow',
  circle: 'Circle',
  image: 'Image',
  text: 'Text',
  gif: 'Gif',
};

export class ShapeFactory {
  static shapeReferences = {
    [TYPES.rectangle]: Rectangle,
    [TYPES.arrow]: Arrow,
    [TYPES.circle]: Circle,
    [TYPES.text]: Text,
    [TYPES.image]: Image,
    [TYPES.gif]: Gif,
  };
  constructor() {
    throw 'Cannot instantiate Factory method';
  }

  static createShape(type, args) {
    if (!ShapeFactory.shapeReferences.hasOwnProperty(type)) {
      throw `Error. ShapeFactory does not have shapes of type "${type}". 
            Available types are ${Object.keys(ShapeFactory.shapeReferences)}`;
    }
    return new ShapeFactory.shapeReferences[type](args);
  }
}
