import { Shape, TYPES } from './shapes';

export class Image extends Shape {
  type = TYPES.image;
  midx = 0;
  midy = 0;
  scaleShape = 1;
  progress = null
  constructor({
    id = -1,
    x = 0,
    y = 0,
    displayName = 'New Image',
    size = 0,
    s3Key = '',
    screenWidth = 640,
    screenHeight = 480,
    scaleDirection = null,
    rotation = 0,
    scaleShape = 1,
  } = {}) {
    super({ id, x, y, displayName, rotation });
    this.size = size;
    this.s3Key = s3Key;
    this.scaleShape = scaleShape;
    this.calculateMidPoint();
    if (scaleDirection) this.scale(screenWidth, screenHeight, scaleDirection);
  }
  scaleExt(scaleWidth, scaleHeight) {
    this.x *= scaleWidth;
    this.y *= scaleHeight;

    this.width *= scaleWidth;
    this.height *= scaleHeight;

    this.midx *= scaleWidth;
    this.midy *= scaleHeight;
  }
  calculateMidPoint() {
    this.midx = (this.x + this.x + this.width) / 2;
    this.midy = (this.y + this.y + this.height) / 2;
  }
  deepCopyExt(shape) {
    const { size, s3Key, midx, midy, scaleShape } = shape;
    this.size = size;
    this.s3Key = s3Key;
    this.midx = midx;
    this.midy = midy;
    this.scaleShape = scaleShape;
  }
  toJSONExt() {
    return {
      size: this.size,
      s3Key: this.s3Key,
      midx: this.midx,
      midy: this.midy,
      scaleShape: this.scaleShape,
    };
  }

  updateScale(value) {
    if (Number((this.scaleShape + value).toFixed(2)) < 0.1) return;
    this.scaleShape = Number((this.scaleShape + value).toFixed(2));
  }

  moveShape(units, direction) {
    if (direction === this.movementDirections.vertical) this.y += units;
    else if (direction === this.movementDirections.horizontal) this.x += units;
  }

  rotateShape(angle) {
    this.rotation += angle;
  }
}
